// body { 
//     font-family: Avenir, sans-serif; margin: 0; 
//   }

body{
    background-color: ghostwhite;
    color: #333;
    background-color: #f3f1f3;
    font-family: Arial,verdana,sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.6em;
    letter-spacing: 0px;

}
  div#quad { 
    font-size: 0; width: 80%; 
    margin: 0 auto;
    // box-shadow: 0 0 12px rgba(0,0,0,0.8);
  }
  div#quad figure { 
    margin: 0; width: 50%; 
    height: auto; transition: 1s; 
    display: inline-block; 
    position: relative; overflow: hidden; 
    padding: 1%;
  }
//   div#quad figure:hover { cursor: pointer; z-index: 4; }
  div#quad figure img { width: 100%; height: auto; }
  div#quad figure:nth-child(1) { transform-origin: top left; }
  div#quad figure:nth-child(2) { transform-origin: top right; }
  div#quad figure:nth-child(3) { transform-origin: bottom left; }
  div#quad figure:nth-child(4) { transform-origin: bottom right; }
  div#quad figure figcaption { 
    margin: 0; opacity: 0; 
    background: rgba(0,0,0,0.3); 
    color: #fff; padding: .3rem; 
    font-size: 1.2rem; 
    position: absolute; 
    bottom: 0; width: 100%;
      transition: 1s 1s opacity; 
  }
  .expanded { transform: scale(2); z-index: 5; }
  .active { padding: 0% !important; }
  div#quad figure.expanded figcaption { opacity: 1; }
  div.full figure:not(.expanded) { pointer-events: none; }

  .header-title-project{
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    margin-top: 1% !important;
    margin-bottom: 1rem;
    text-align: center;
    white-space: pre-wrap;
    font-size: 1.8em;
    color: #000;
    font-family: ltc-bodoni-175;
    font-weight: 400;
    font-style: normal;
    line-height: 1em;
    text-transform: uppercase;
    // letter-spacing: 6px;
    padding-left: 16px;
    max-width: 40%;
    margin: auto;
    margin-bottom: 1%;
    
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header-title-project{
    max-width: none;
    font-size: 1.2em;
    // font-weight: 400;
    
   }
   div#quad{
    width: 75%;
    padding: 1%;
    
   }
   div#quad figure{
    width: 75%;
    left: 12%;
   }
 }
 @media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .header-title-project{
    font-size: 1.6em;
  }
   div#quad{
    width: 75%;
    left: 12%;
   }
 }

 @media only screen and (max-width: 767px) {
   /* CSS rules specific to smartphones */
   .header-title-project{
    max-width: none;
    font-size: 1em;
    // font-weight: 400;
    
   }
   div#quad{
    width: 100%;
    padding: 1%;
   }
   div#quad figure{
    width: 100%;
   }
   }