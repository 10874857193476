* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: cursive;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}


/* NAVBAR STYLING STARTS */
.navbar {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    background-color: #f3f1f3;
    color: #000000;
}
.nav-links{
    padding-top: 20px;
}

.nav-links a {
    color: #000000;
}

/* LOGO */
.main-div-logo {
   width: 60%;
   margin-left: 2%;

}
.logo-image {
    width: 20%;
    cursor: pointer;
 
 }

/* NAVBAR MENU */
.menu {
    display: flex;
    gap: 1em;
    font-size: 18px;
    z-index: 1;
}

.menu li:hover {
    background-color: #f3f1f3;
    border-radius: 5px;
    transition: 0.3s ease;
}

.menu li {
    padding: 5px 14px;
}

/* DROPDOWN MENU */
.services {
    position: relative;
}

.dropdown {
    background-color: #f3f1f3;
    padding: 1em 0;
    position: absolute;
    /*WITH RESPECT TO PARENT*/
    display: none;
    border-radius: 8px;
    top: 35px;
}

.dropdown li+li {
    margin-top: 10px;
}

.dropdown li {
    padding: 0.5em 1em;
    width: 8em;
    text-align: center;

}

.dropdown li:hover {
    background-color: #f3f1f3;
}

.services:hover .dropdown {
    display: block;
}

input[type=checkbox] {
    display: none;
}

/* HAMBURGER MENU */
.hamburger {
    display: none;
    font-size: 24px;
    user-select: none;
}

.social-icons-navbar{
    display: inline-block;
    vertical-align: middle;
    font-size: 25px;
}

  
  .social-icons a {
    margin: 0 10px;
    color: #ffffff; /* customize the color */
  }
  .a-social {
    padding-right: 10px;
  }

/* APPLYING MEDIA QUERIES */
@media (max-width: 768px) {
    .menu {
        display: none;
        position: absolute;
        background-color: #f3f1f3;
        right: 0;
        left: 0;
        text-align: center;
        padding: 16px 0;
    }

    .menu li:hover {
        display: inline-block;
        background-color: #f3f1f3;
        transition: 0.3s ease;
    }

    .menu li+li {
        margin-top: 12px;
    }

    input[type=checkbox]:checked~.menu {
        display: block;
    }

    .hamburger {
        display: block;
    }

    .dropdown {
        left: 50%;
        top: 30px;
        transform: translateX(35%);
    }

    .dropdown li:hover {
        background-color: #f3f1f3;
    }
    .main-div-logo {
        width: 100%;
        margin-left: 2%;
     
     }
     .logo-image {
         width: 30%;
      
      }
      .main-logo {
        width: 90% !important; 

      }
      .main-logo-image{
        width: 50% !important;
      }

    }





    @media only screen and (min-width: 769px) and (max-width: 913px) {

        .main-div-logo {
            width: 30%;
            margin-left: 2%;
         
         }
         .logo-image {
             width: 50%;
          
          }
          
          .main-logo {
            width: 100% !important; 

          }
          .main-logo-image{
            width: 30% !important;
          }
       }

       @media only screen and (min-width: 914px) and (max-width: 1560px) {

        .main-div-logo{
            width: 50%;
            margin-left: 2%;
         
         }
         .logo-image{
             width: 30%;
          
          }

          .main-logo {
            width: 100% !important; 

          }
          .main-logo-image{
            width: 20% !important;
          }

       }