body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


hr{
    border: none;
    color: #bbb;
    background-color: #bbb;
    height: 1px;
    width: 50%;
    margin-top: 2% !important;
    margin: auto;
    padding-left: 16px;
}

.footer{
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1rem;
    text-align: center;
    white-space: pre-wrap;
    font-size: 1.4em;
    color: #000;
    font-family: ltc-bodoni-175;
    font-weight: 400;
    font-style: normal;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 6px;
    padding-left: 16px;
}

.main-button {
    background: #000;
    color: #fff;
    border-style: none;
    font-family: ltc-bodoni-175;
    font-weight: 400;
    font-style: normal;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: .2em;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    margin-top: 50px;
    border-radius: 3px;
  }


  @media only screen and (max-width: 720px) {
    /* CSS rules specific to smartphones */

.main-button {
 font-size: 20px;
 font-weight: 300;
 padding: 5px; 

}
.footer{
  font-size: 0.7em;
}

    }