#my-body-id{
  overflow: hidden;
}
#slider {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

#main-div{
    position: relative;

    /* display: inline-block; */
}

#main-title{
position: absolute;
font-family: serif;
letter-spacing: .3em;
font-size: 40px;
z-index: 1;
text-transform: uppercase;
/* left: 70px; */
/* top: 7%; */
}
#desc{
    position: absolute;
    font-family: serif;
    /* letter-spacing: .2em; */
    text-transform: uppercase;
    font-size: 55px;
    z-index: 1;
    left: 70px;
    top: 40%;
    color: #ffffff;
    padding-bottom: 60px;
    margin-bottom: 10px;
    
    }
    .box-text{
        display: block;
    }
.image-container {
    position: relative;
  }
  
  .group-div{
    position: absolute;
    top: 60%;
    left: 80px;
    margin-bottom:40px;
  }


  #social{
    position: absolute;
    top: 90%;
    left: 70px;
    margin-bottom:40px;
  }
  .social-icons {
    display: flex;
    justify-content: center;
  }
  
  .social-icons a {
    margin: 0 10px;
    color: #ffffff; /* customize the color */
    font-size: 30px; /* customize the size */
  }

  span{
    padding-bottom: 3%;
  }




  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    
   #desc{
    font-size: 35px;
   }
  }

  @media only screen and (max-width: 767px) {
    /* CSS rules specific to smartphones */
    #desc{
      left: 10px;
      top: 40%;
      font-size: 22px;
      padding: 0;
     }

     .group-div{
       left: 10px;
       top: 50%;
      }
     #social{
        left: 10px;
        top: 80%;   
      }

    }
    
    