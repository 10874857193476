* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  :root {
    --purple: hsl(240, 80%, 89%);
    --pink: hsl(0, 59%, 94%);
    --light-bg: hsl(204, 37%, 92%);
    --light-gray-bg: hsl(0, 0%, 94%);
    --white: hsl(0, 0%, 100%);
    --dark: hsl(0, 0%, 7%);
    --text-gray: hsl(0, 0%, 30%);
  }
  
  body {
    background: var(--light-bg);
    font-family: "Space Grotesk", sans-serif;
    color: var(--dark);
  }
  
  h3 {
    font-size: 1.5em;
    font-weight: 700;
  }
  
  p {
    font-size: 1em;
    line-height: 1.7;
    font-weight: 300;
    color: var(--text-gray);
  }
  
  .description {
    white-space: wrap;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .wrap {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    margin: auto;
    gap: 24px;
    padding: 24px;
    flex-wrap: nowrap;
  }
  
  .box {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    padding: 24px;
    background: #fff;
  }
  
  .box-top {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 12px;
    margin-bottom: 36px;
  }
  
  .box-image {
    width: 100%;
    height: 360px;
    object-fit: cover;
    object-position: 50% 20%;
  }
  
  .title-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .box-title {
    border-left: 3px solid var(--purple);
    padding-left: 12px;
  }
  
  .user-follow-info {
    color: hsl(0, 0%, 60%);
  }
  
  .card-button {
      margin: 0% !important;
  }
  
  .button:hover {
    background: var(--purple);
  }
  
  .fill-one {
    background: var(--light-bg);
  }
  
  .fill-two {
    background: var(--pink);
  }
  
  /* RESPONSIVE QUERIES */
  
  @media (min-width: 320px) {
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 460px) {
    .title-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 640px) {
    .box {
      /* flex-basis: calc(50% - 12px); */
      width: 100%;
    }
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 840px) {
    .title-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 1024px) {
    .box {
      /* flex-basis: calc(33.3% - 16px); */
      width: 100%;
    }
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
    .user-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 1100px) {
    .box {
        flex-basis: calc(40.3% - 0px);

    }
    .wrap{
        width: 70%;
    }
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .box-title{
      font-size: 1.2em;
    }
    
    .main-button {
      font-size: 20px;
      font-weight: 200;
      padding: 5px 5px 5px 5px; 
    }

   }
 
   @media only screen and (max-width: 767px) {
     /* CSS rules specific to smartphones */
     .wrap{
      flex-wrap: wrap;
      padding: 5px;
     }
.box-title{
  font-size: 1.2em;
}

.main-button {
  font-size: 20px;
  font-weight: 200;
  padding: 5px 5px 5px 5px; 
}

     }
  