body{
    background-color: ghostwhite;
    color: #333;
    background-color: #f3f1f3;
    font-family: Arial,verdana,sans-serif;
    font-size: 14px;

    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.6em;
    letter-spacing: 0px;
    overflow: scroll ;

}

.header-title{
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1rem;
    text-align: center;
    white-space: pre-wrap;
    font-size: 1.8em;
    color: #000;
    font-family: ltc-bodoni-175;
    font-weight: 400;
    font-style: normal;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 6px;
    padding-left: 16px;
}

.p-footer{
    display: block;
    margin: 0 0 1.6em;
    text-align: center;
    white-space: pre-wrap;
    width: 50%;
    margin: auto;
    padding-left: 16px;

}
.p-header{
    display: block;
    margin: 0 0 1.6em;
    text-align: center;
    white-space: pre-wrap;
    width: 50%;
    margin: auto;
    padding-left: 16px;

}

@media only screen and (max-width: 767px) {
    /* CSS rules specific to smartphones */
    /* .wrap{
     flex-wrap: wrap;
    } */
.box-title{
 font-size: 1.2em;
}

.header-title{
    font-size: 1.4em;
}
.p-header{
    width: 100%;
    font-size: 0.8em;
    text-align: justify;
    padding-right: 4%;
 }
 .p-footer{
    font-size: 0.6em;
    width: 100%;
   
 }
 .footer{
    margin-bottom: 0;
 }

    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        /* .wrap{
            flex-wrap: wrap;
           } */
        #desc{
         font-size: 35px;
        }
        .p-header{
            width: 100%;
            font-size: 0.9em;
            text-align: center;
        
         }
       }
     
    



